<template>
  <div class="modal-delete modal">
    <div class="modal-content">
      <template v-if="step === 1">
        <div class="header">
          <div class="header-icon">
            <i class="far fa-trash-alt"></i>
          </div>
          <div class="header-question">
            <h1>¿Está seguro que desea eliminar esta {{ screen }}?😥</h1>
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-primary" @click="$emit('update:delete')">
            Eliminar
          </button>
          <button class="btn btn-primary" @click="$emit('update:cancel')">
            Cancelar
          </button>
        </div>
      </template>

      <template v-else-if="step === 2">
        <div class="body text-center">
          <Loading className="cms" />
          <h1 class="mt-2">Eliminando {{ screen }}</h1>
          <p>Espere porfavor ..</p>
        </div>
      </template>

      <template v-else>
        <div class="header">
          <div class="header-icon">
            <i class="far fa-trash-alt"></i>
          </div>
        </div>
        <div class="body text-center">
          <h1 class="mt-1">
            {{ message }}
          </h1>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
export default {
  emits: ['update:delete', 'update:cancel'],
  props: {
    screen: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    message: {
      type: String,
    },
  },
  components: {
    Loading: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Loading" */ '@/components/Loading.vue')
    ),
  },
};
</script>
